import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

function Randomizer(props) {
  const millsAds = [
    {
      image: '/ads/werners.jpg',
      link: 'https://wernersmercedes.com/?utm_source=Mills+Publishing+Digital+Publications&utm_campaign=Salt+Lake+Acting+Company',
      alt: 'Werner Mercedes ad',
      class: 'webFormat',
    },
  ];

  const [randomAd] = React.useState(
    millsAds[Math.floor(Math.random() * millsAds.length)]
  );

  return (
    <>
      <br />
      <OutboundLink
        href={randomAd.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={randomAd.image}
          alt={randomAd.alt}
          className={randomAd.class}
        />
      </OutboundLink>
      <br />
      <br />
      <hr />
      <br />
    </>
  );
}

export default Randomizer;
